const questions = [
  // 1 Слайд
  {
    questionText:
      "Уф, наконец собрал все вещи! С пакетами справлюсь, но шкаф в одиночку спустить не смогу. ",
    answerOptions: [
      {
        answerText: "Оставлю его тут. В новую жизнь — с новой мебелью.",
        answerResult:
          "Шкаф хороший, оставлять жалко, а друзья могут быть заняты. Хорошо, что есть «Максим»: выберу тариф, который включает перевозку и помощь грузчика.",
        result: false,
      },
      {
        answerText: "Позвоню друзьям, они точно помогут.",
        answerResult:
          "Шкаф хороший, оставлять жалко, а друзья могут быть заняты. Хорошо, что есть «Максим»: выберу тариф, который включает перевозку и помощь грузчика.",
        result: false,
      },
      {
        answerText: "Выберу тариф «Стандартный кузов + грузчик» в приложении",
        answerResult:
          "Шкаф хороший, оставлять жалко, а друзья могут быть заняты. Хорошо, что есть «Максим»: выберу тариф, который включает перевозку и помощь грузчика.",
        result: true,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд

  {
    questionText:
      "Это возьму с собой, а это — на мусорку. Вот только один я это точно не унесу.",
    answerOptions: [
      {
        answerText: "Пусть со старыми вещами разбираются новые жильцы.",
        answerResult:
          "Попросить помощи — вот хороший вариант. Поблагодарю и поставлю грузчику лайк за хорошую работу в приложении maxim.",
        result: false,
      },
      {
        answerText: "Пока грузчик не ушел, попрошу помощи с вещами.",
        answerResult:
          "Попросить помощи — вот хороший вариант. Поблагодарю и поставлю грузчику лайк за хорошую работу в приложении maxim.",
        result: true,
      },
      {
        answerText: "Устрою гаражную распродажу во дворе.",
        answerResult:
          "Попросить помощи — вот хороший вариант. Поблагодарю и поставлю грузчику лайк за хорошую работу в приложении maxim.",
        result: false,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд
  {
    questionText:
      "Пора везти вещи в новую квартиру! Как думаешь, получится перевезти все это разом?",
    answerOptions: [
      {
        answerText: "Это вряд ли, тут и 3 грузовика не справятся.",
        answerResult:
          " В «Максим» можно выбрать подходящий автомобиль, думаю, для этих вещей стандартного грузового авто хватит.",
        result: false,
      },
      {
        answerText: "Вещей не так много, и легковое авто подойдет.",
        answerResult:
          " В «Максим» можно выбрать подходящий автомобиль, думаю, для этих вещей стандартного грузового авто хватит.",
        result: false,
      },
      {
        answerText: "Одного грузовика вполне хватит.",
        answerResult:
          " В «Максим» можно выбрать подходящий автомобиль, думаю, для этих вещей стандартного грузового авто хватит.",
        result: true,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд
  {
    questionText:
      "Друг обещал, что встретит водителя и грузчика в новой квартире. Как он поймет, когда они приедут?",
    answerOptions: [
      {
        answerText:
          "Да легко! Можно рассчитать время в пути и позвонить другу.",
        answerResult:
          "Можно надеяться на чутье или считать время, но надежнее отправить ссылку на маршрут. Смотреть, где едет авто с вещами, удобно на онлайн-карте.",
        result: false,
      },
      {
        answerText: "Только надеяться на пингвинское чутье. ",
        answerResult:
          "Можно надеяться на чутье или считать время, но надежнее отправить ссылку на маршрут. Смотреть, где едет авто с вещами, удобно на онлайн-карте.",
        result: false,
      },
      {
        answerText: "Посмотреть, где едет авто с вещами, на онлайн-карте.",
        answerResult:
          "Можно надеяться на чутье или считать время, но надежнее отправить ссылку на маршрут. Смотреть, где едет авто с вещами, удобно на онлайн-карте.",
        result: true,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд
  {
    questionText: "Отдал ключи новым жильцам. Пора ехать в новую квартиру!",
    answerOptions: [
      {
        answerText: "Закажу такси, чтобы быстрее приехать и разобрать вещи.",
        answerResult:
          "Переезд — дело непростое. Кажется, Пингвинчик не справится с вещами сам, пора поспешить к нему.",
        result: true,
      },
      {
        answerText: "Прогуляюсь по городу. ",
        answerResult:
          "Переезд — дело непростое. Кажется, Пингвинчик не справится с вещами сам, пора поспешить к нему.",
        result: false,
      },
      {
        answerText: "А я не хочу уезжать.",
        answerResult:
          "Переезд — дело непростое. Кажется, Пингвинчик не справится с вещами сам, пора поспешить к нему.",
        result: false,
      },
    ],
    image: "images/05.webp",
  },

  // 6 слайд
  {
    questionText:
      "Успел! Грузчик помог пингвину занести все вещи на этаж. Как поблагодарить его за хорошую работу?",
    answerOptions: [
      {
        answerText: "Добавить в друзья в соцсетях.",
        answerResult:
          "Общение, конечно, здорово. Грузчику будет приятно получить чаевые за работу. Сумма добавится к цене заказа.",
        result: false,
      },
      {
        answerText: "Оставить чаевые.",
        answerResult:
          "Общение, конечно, здорово. Грузчику будет приятно получить чаевые за работу. Сумма добавится к цене заказа.",
        result: true,
      },
      {
        answerText: "Сказать «Спасибо».",
        answerResult:
          "Общение, конечно, здорово. Грузчику будет приятно получить чаевые за работу. Сумма добавится к цене заказа.",
        result: false,
      },
    ],
    image: "images/06.webp",
  },
];

export default questions;
