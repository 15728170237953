import React, { useState, useEffect } from "react";
import Title from "../Title/Title";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";
import { SITE_URL, GO_URL } from "../../state/consts";
import { IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";

import { WhatsappShareButton, TelegramShareButton } from "react-share";

import { WhatsappIcon, TelegramIcon } from "react-share";

function Result({
  score,
  title,
  text,
  imageSrc,
  posterContainer,
  optionsContainer,
  handlerBack,
}) {
  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ImageURL = SITE_URL + imageSrc;

  const handleClick = (e) => {
    e.preventDefault();
    window.open(GO_URL);
  };

  // кнопка скопировать
  const [open, setOpen] = useState(false);

  const testClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  return (
    <>
      <div className={posterContainer}>
        <img src={imageSrc} className={styles.image} alt="" />
      </div>
      <div className={classNames(optionsContainer, styles.before)}>
        <div
          className={classNames({
            [styles.resultPosterContainer]: true,
            [styles.onScrollOption]: offset,
          })}
        >
          <div className={styles.contentWrapper}>
            <div className={styles.score}>{score}</div>
            <Title value={title} />
            {text}
          </div>
          <div className={styles.buttonBox}>
            <a
              href="https://taximaxim.onelink.me/KKXl/quiztruck"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                value="Перейти в приложение"
                className={styles.buttonPresent}
              />
            </a>
            <Button
              value="Пройти заново"
              className={styles.outline}
              onClick={handlerBack}
              id="refresh"
            />
          </div>

          <div
            className={classNames(styles.contentWrapper, styles.socialWrapper)}
          >
            <Paragraph value="Поделиться" />
            <div className={styles.social}>
              <WhatsappShareButton
                url={SITE_URL}
                title="Помог Максимке переехать в новую квартиру — пройди игру от сервиса «Максим» и узнай, как сделать переезд максимально простым."
                image={ImageURL}
                id="whatsapp"
              >
                <WhatsappIcon
                  size={48}
                  round={true}
                  className={classNames(styles.icon, styles.whatsapp)}
                />
              </WhatsappShareButton>

              <TelegramShareButton
                url={SITE_URL}
                title="Помог Максимке переехать в новую квартиру — пройди игру от сервиса «Максим» и узнай, как сделать переезд максимально простым."
                image={ImageURL}
                id="telegram"
              >
                <TelegramIcon
                  size={48}
                  round={true}
                  className={classNames(styles.icon, styles.telegram)}
                />
              </TelegramShareButton>

              <div id="sharelink">
                <IconButton
                  onClick={testClick}
                  color="primary"
                  className={styles.share}
                >
                  <ShareIcon />
                </IconButton>
                <Snackbar
                  message="Скопировано в буфер обмена"
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  autoHideDuration={1000}
                  onClose={() => setOpen(false)}
                  open={open}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Result;
